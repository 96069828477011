
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'ApplyCash',
})
export default class ApplyCash extends Vue {
    private applyData: any = {
        bankCode: '',
    };
    private activeEdit: number = 1;
    @Prop({
        type: Boolean,
        default: false,
    }) private showFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: () => {
            return {};
        },
    }) private profitTab!: any;
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('showFlag') // 监听父组件传过来的值
    private showFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private submit() {
        if (this.profitTab.waitWithDrawProfitAmount && this.profitTab.waitWithDrawProfitAmount > 0) {
            this.$httpService.postData({}, '/apiProxy/api/frontend/profit/withdraw')
            .then((res: any) => {
                this.$message.success('申请成功');
                this.closeHandleTodo(1);
            });
        } else {
            this.$message.warning('提现金额不足~');
        }
    }
    private edit() {
        // ...
    }
    private submitEditBank() {
        // ...
    }
    private editBank() {
        this.$router.push('/company/material?active=2');
        this.closeHandleTodo(0); // 给父组件传值
    }
}
