
import { ElForm } from 'element-ui/types/form';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
    name: 'ApplyAuthorizeDriveItem',
})
export default class ApplyAuthorizeDriveItem extends Vue {
    @Prop({
        type: Object,
        default: null,
    }) private authCodeData!: any;
    @Prop({
        type: Boolean,
        default: false,
    }) private disabled?: boolean;
    @Prop({
        type: Number,
        default: 0,
    }) private index!: number;
    private level: number = 0;
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private industryOptions: any = [];
    private authCodeRules: any = {
        coverUrl: [
            { required: this.authCodeData.name !== '-', message: '请上传设备图片', trigger: 'change' },
        ],
        name: [
            { required: true, message: '请输入设备名称', trigger: 'change' },
        ],
        industry: [
            { required: true, message: '请输入设备行业', trigger: 'change' },
        ],
        vendor: [
            { required: true, message: '请输入设备厂商', trigger: 'change' },
        ],
        model: [
            { required: true, message: '请输入设备型号/协议', trigger: 'change' },
        ],
    };
    private industryProps: any = {
        value: "id",
        label: "name",
        children: 'child',
        checkStrictly: true,
    };
    @Emit('deleteCurrentDrive')
    private handleDelete(): number {
        return this.index;
    }
    private created() {
        this.getOptions();
    }
    private getOptions() {
        this.$httpService.getData({pid: 0}, '/apiProxy/api/frontend/drive/industry')
        .then((rep: any) => {
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            this.industryOptions = rep;
        });
    }
    private initOptions(options: any, itemList: any) {
        if (this.level >= itemList.length) {
            return;
        }
        const data = itemList[this.level];
        options.forEach((item: any) => {
            if (data.pid === item.id) {
                item.children = data.rep;
                if (this.level === itemList.length - 2) {
                    item.children.forEach((childItem: any) => {
                        childItem.leaf = true;
                    });
                }
                this.level++;
                this.initOptions(item.children, itemList);
            }
        });
    }
    private loadOptions(id: number) {
        return new Promise((resolve, reject) => {
            this.$httpService.getData({ pid: id }, '/apiProxy/api/frontend/drive/industry')
            .then((rep: any) => {
                const data = {rep, pid: id};
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
         });
    }
    // 打开文件
    private openFile() {
        const file: any = this.$refs.file;
        file.click();
    }
    // 上传附件
    private chooseFile(event: any) {
        const fileData = event.target.files[0];
        const accepts = ['image/jpg', 'image/jpeg', 'image/png'];
        const isAccept = this.isAcceptType(fileData.type, accepts);
        if (!isAccept) {
            this.$message.error('上传失败！上传格式仅支持.jpg、.jpeg、.png');
            event.target.value = null;
            return;
        }
        const isLt2M = fileData.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            this.$message.warning('上传图片大小不能超过 10MB!');
            event.target.value = null;
            return;
        }

        const param = new FormData();
        param.append('file', fileData);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.authCodeData.coverUrl = res.path;
            const authorizeForm = this.$refs.authorizeForm! as ElForm;
            authorizeForm.validateField('coverUrl');
        });
        event.target.value = null;
    }
    private isAcceptType(ext: string, accepts?: any) {
        const AcceptType = accepts ? accepts : ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'];
        return AcceptType.indexOf(ext.toLowerCase()) !== -1;
    }
    private industryNameChange(val: any)  {
       const industryName: any = this.$refs.cascader;
       this.authCodeData.industryName = industryName.getCheckedNodes()[0].pathLabels.join(',');
    }

}
