
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: "BaseTable",
    components: {
        MyRenderDom: {  // 函数式组件
            functional: true,   // 设置为true 即表示该组件为一个函数组件
            props: {
                row: Object,
                render: Function,
                index: Number,
                column: {
                    type: Object,
                    default: null,
                },
            },
            render: (h: any, ctx: any) => {
                const params: any = {
                    row: ctx.props.row,
                    index: ctx.props.index,
                };
                if (ctx.props.column) {
                    params.column = ctx.props.column;
                }
                return ctx.props.render(h, params);
            },
        },
    },
})
export default class Default extends Vue {
    @Prop({
        type: Boolean,
        default: () => {
            return false;
        },
    }) private resetTable!: boolean;
    @Prop(Boolean) private loading?: boolean;
    @Prop(Boolean) private mutiSelect?: boolean;
    @Prop(Array) private tableHeader?: [];
    @Prop(Array) private tableData?: [];
    @Prop(Number) private total?: number;
    @Prop(Number) private currentPage?: number;
    @Prop(Object) private defaultSort?: object;
    @Prop(Function) private spanMethod?: any;
    @Prop({
        type: String,
        default: null,
    }) private rowKey!: string;
    @Prop({
        type: Object,
        default: () => {
            return {children: 'children', hasChildren: 'hasChildren'};
        },
    }) private treeProps?: object;
    @Prop({
        type: String,
        default: '',
    }) private keyWord!: string;
    @Prop({
        type: Boolean,
        default: false,
    }) private scrollTopFlag!: boolean;
    @Prop({
        type: Boolean,
        default: true,
    }) private showPagination!: boolean;
    @Prop({
        type: Boolean,
        default: false,
    }) private border!: boolean;
    @Prop({
        type: Number,
        default: 10,
    }) private pageSize!: number;
    @Prop({
        type: Array,
        default: () => {
            return [10, 20, 50, 100];
        },
    }) private pageSizes!: number[];
    @Prop({
        type: String,
        default: "total, sizes, prev, pager, next, jumper",
    }) private pageLayout!: string;
    @Prop({
        type: Number,
        default: 260,
    }) private reduceHeight!: number;
    private maxheight: any = window.innerHeight - 260;
    private startScrollTop: boolean = false;
    // 给父组件传值
    @Emit('handleSizeChange')
    private handleSizeChange(obj: any): any {
        return obj;
    }
    @Emit('handleCurrentChange')
    private handleCurrentChange(obj: any): any {
        return obj;
    }
    @Watch('scrollTopFlag')
    private scrollTopFlagChange(val: boolean) {
        if (val) {
            this.startScrollTop = val;
        }
    }
    private tableRowClassName(rowData: any) {
        const {row, rowIndex} = rowData;
        if (this.keyWord !== '') {
            if (row.name.indexOf(this.keyWord) > -1) {
                if (this.startScrollTop) {
                    const baseTable: any = this.$refs.baseTable;
                    const elTable = baseTable.$el;
                    if ( elTable ) {
                        const targetTop = elTable
                            .querySelectorAll('.el-table__body tr')[rowIndex].getBoundingClientRect().top;
                        const containerTop = elTable.querySelector('.el-table__body').getBoundingClientRect().top;
                        baseTable.bodyWrapper.scrollTop = targetTop - containerTop;
                        this.startScrollTop = false;
                    }
                }
                return 'row-row';
            }
        } else {
            // 当keyWord为空时，需要滚动，默认置顶
            if (this.startScrollTop) {
                const baseTable: any = this.$refs.baseTable;
                const elTable = baseTable.$el;
                if ( elTable ) {
                    const targetTop = elTable
                        .querySelectorAll('.el-table__body tr')[0].getBoundingClientRect().top;
                    const containerTop = elTable.querySelector('.el-table__body').getBoundingClientRect().top;
                    baseTable.bodyWrapper.scrollTop = targetTop - containerTop;
                    this.startScrollTop = false;
                }
            }
        }
        return '';
    }
    @Emit('SelectionChange')
    private SelectionChange(obj: any): any {
        return obj;
    }
    @Emit('sortChange')
    private sortChange(obj: any): any {
        return obj;
    }
    @Emit('filterChange')
    private filterChange(obj: any): any {
        return obj;
    }
    @Watch('tableData')
    private tableDataChange(val: any) {
        if (val) {
            this.$nextTick(() => {
                const baseTable: any = this.$refs.baseTable;
                if (baseTable) {
                    baseTable.doLayout();
                }
            });
        }
    }
    private sort(prop: string, order: string) {
        const baseTable: any = this.$refs.baseTable;
        baseTable.sort(prop, order);
    }
    private getHeight() {
        if (this.reduceHeight === 0) {
            this.reduceHeight = 260;
        }
        this.maxheight = window.innerHeight - this.reduceHeight;
    }
    private mounted() {
        if (this.reduceHeight === 0) {
            this.reduceHeight = 260;
        }
        this.maxheight = window.innerHeight - this.reduceHeight;
        window.addEventListener('resize', this.getHeight);
    }
    private beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    }
}
