
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component({
    name: 'DevAuthorizeCodeList',
})
export default class DevAuthorizeCodeList extends Vue {
    private tableData: any = [];
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
    };
    private total: number = 0;
    private loading: boolean = false;
    // compute merge row start
    private spanArr: any = [];
    // end
    public getDataList() {
        this.loading = true;
        this.refreshHandleTodo();
        this.$httpService.getData(this.searchParam, '/apiProxy/api/frontend/drive/code-list').then((res: any) => {
           if (res && res.total > 0) {
                const { list, total } = res;
                this.total = total;
                const newList: any = [];
                list.forEach((item: any) => {
                    if (item.devs) {
                        item.devs.forEach((dev: any) => {
                            const devData = { authCode: item.authCode,
                                authCodeId: item.id,
                                price: item.price,
                                status: item.status,
                                type: item.type,
                                version: item.version,
                                driveName: item.driveName,
                                ...dev };
                            if (devData.type === 2 && devData.goodsName) {
                                devData.name = devData.goodsName;
                            }
                            newList.push(devData);
                        });
                    }
                });
                this.tableData = newList;
                this.getSpanArr(newList);
           } else {
               this.total = 0;
               this.tableData = [];
               this.spanArr = [];
           }
        }).finally(() => {
            this.loading = false;
        });
    }
    @Emit('editHandle')
    private editHandleTodo(obj: object): object {
        return obj;
    }
    @Emit('refreshHandle')
    private refreshHandleTodo() {
        //
    }
    @Emit('detailHandle')
    private detailHandleTodo(obj: object): object {
        return obj;
    }
    private created() {
       this.getDataList();
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private detailClick(obj: any) {
        this.detailHandleTodo(obj);
    }
    private deleteClick(obj: any) {
        this.$confirm(`请确定是否要删除该驱动？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.postData({}, `/apiProxy/api/frontend/drive/del/${obj.authCodeId}`).then((rep: any) => {
                this.$message.success(`您已删除该驱动`);
                this.getDataList();
            });
        });
    }
    private editClick(obj: any) {
        this.editHandleTodo(obj);
    }
    private driveTypeFormatter(row: any, column: any, cellValue: any, index: any) {
        let typeName = '-';
        if (cellValue === 1) {
            typeName = '专用设备驱动';
        } else if (cellValue === 2) {
            typeName = '通用驱动';
        }
        return typeName;
    }
    private defaultTypeFormatter(row: any, column: any, cellValue: any, index: any) {
        if (!cellValue || cellValue === '') {
            return '-';
        } else {
            return cellValue;
        }
    }
    private objectSpanMethod(obj: any) {
       const { row, column, rowIndex, columnIndex } = obj;
       if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 7) {
            const rowCount = this.spanArr[rowIndex];
            const colCount = rowCount > 0 ? 1 : 0;
            return {
                rowspan: rowCount,
                colspan: colCount,
            };
        } else {
            return {
                rowspan: 1,
                colspan: 1,
            };
        }
    }
    private getSpanArr(data: any) {
        const result = [];
        let pos = 0;
        for (let i = 0; i < data.length; i++) {
            if (i === 0) {
                result.push(1);
                pos = 0;
            } else {
                // 判断当前元素与上一个元素是否相同
                if (data[i].authCode === data[i - 1].authCode) {
                    result.push(0);
                    result[pos] += 1;
                } else {
                    result.push(1);
                    pos = i;
                }
            }
        }
        this.spanArr = result;
    }
}
