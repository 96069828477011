
import { Component, Vue, Prop, Watch, Emit, Model } from 'vue-property-decorator';
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

@Component({
    name: 'WangEditor',
    components: {
        Editor,
        Toolbar,
    },
})
export default class WangEditor extends Vue {
    @Model('change') private value?: string;
    @Prop({
        type: Boolean,
        default: true,
    }) private editable?: boolean;
    private html: string = '';
    private editor: any = null;
    // 'head',  // 标题
    // 'bold',  // 粗体
    // 'fontSize',  // 字号
    // 'fontName',  // 字体
    // 'italic',  // 斜体
    // 'underline',  // 下划线
    // 'strikeThrough',  // 删除线
    // 'foreColor',  // 文字颜色
    // 'backColor',  // 背景颜色
    // 'link',  // 插入链接
    // 'list',  // 列表
    // 'justify',  // 对齐方式
    // 'quote',  // 引用
    // 'emoticon',  // 表情
    // 'image',  // 插入图片
    // 'table',  // 表格
    // 'code',  // 插入代码
    // 'undo',  // 撤销
    // 'redo'  // 重复
    private toolbarConfig: any = {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        excludeKeys: [ 'emotion', 'uploadVideo'/* 隐藏哪些菜单 */ ],
    };
    private editorConfig: any = {
        placeholder: "请输入内容...",
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
            uploadImage: {
                customUpload: (file: any, insertFn: any) => {
                    // file 即选中的文件
                    // 自己实现上传，并得到图片 url alt href
                    const form = new FormData();
                    form.append("file", file);
                    this.$httpService.postData(form, '/apiProxy/api/frontend/file/upload_image')
                    .then((response: any) => {
                        // 最后插入图片
                        insertFn(response.path, '', response.path);
                    });
                },
            },
        },
    };
    @Watch('value')
    private valueWatch(val: string) {
        if (val !== this.html) {
            this.html = val;
        }
    }
    // 给父组件传值
    @Emit('blurHandle')
    private blurTodo(): any {
        return this.html;
    }
    // 给父组件传值
    @Emit('change')
    private changeTodo(): any {
        return this.html;
    }
    private mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        // if (this.value !== '') {
        //     setTimeout(() => {
        //         this.html = `<p>${this.value}</p>`;
        //     }, 500);
        // }
    }
    private onCreated(editor: any) {
        this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
        if (!this.editable) {
            this.editor.disable();
        }
    }
    private onChange(editor: any) {
        if (this.html === '<p><br></p>') {
            this.html = '';
        }
        if (this.html !== this.value) {
            this.changeTodo();
        }
    }
    private onBlur(editor: any) {
        if (this.html === '<p><br></p>') {
            this.html = '';
        }
        this.blurTodo();
    }
    private destroyed() {
        const editor = this.editor;
        if (editor == null) {
            return;
        }
        editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
    }
}
