
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import BaseTable from "@/components/BaseTable/index.vue";
import ApplyCash from "./component/ApplyCash.vue";
import CashDetailDrawer from "./component/CashDetailDrawer.vue";
import OrderDetailDialog from "../../orderManage/list/component/OrderDetailDialog.vue";
import { moneyFormat } from '@/utils/utils';

@Component({
    name: "ProfitManage",
    components: {
        ContentTitle,
        BaseTable,
        ApplyCash,
        CashDetailDrawer,
        OrderDetailDialog,
    },
})
export default class ProfitManage extends Vue {
    private moneyFormat: any = moneyFormat;
    private loading: boolean = false;
    private contentTitle: string = "分润管理";
    private certStatus: number = 0;
    private activePage: string = '1';
    private total = 0;
    private searchParam = {
        pageSize: 10,
        pageNo: 1,
    };
    private tableData = [];
    private tableHeader = [
        {
            width: "150",
            prop: "orderNo",
            label: "订单编号",
            render: (h: any, scope: any) => {
                return h('span', {
                    class: ['text-blue'],
                    on: {
                        click: () => this.detailClick(scope.row),
                    },
                }, `${scope.row.orderNo}`);
            },
        },
        {
            width: "200",
            prop: "goodsTitle",
            label: "商品名称",
        },
        {
            width: "260",
            prop: "belongCompanyName",
            label: "归属客户",
        },
        {
            width: "150",
            align: "right",
            prop: "payAmount",
            label: "实付金额",
        },
        {
            width: "150",
            align: "right",
            prop: "profitAmount",
            label: "分润金额",
        },
        {
            width: "100",
            prop: "profitStatusName",
            label: "状态",
            formatter: (row: any, column: any, cellValue: any, index: any) => {
                // 待确认
                if (row.profitStatus === 'wait_confirm') {
                    return `<span style="color: rgba(255, 0, 0, 0.647058823529412);">${cellValue}</span>`;
                // 待提现
                } else if (row.profitStatus === 'wait_withdraw') {
                    return `<span style="color: rgba(255, 102, 0, 0.647058823529412);">${cellValue}</span>`;
                // 提现中
                } else if (row.profitStatus === 'withdrawing') {
                    return `<span style="color: #409EFF;">${cellValue}</span>`;
                // 已提现
                } else if (row.profitStatus === 'wait_withdrawal') {
                    return `<span style="color: #36AB60;">${cellValue}</span>`;
                } else {
                    return `<span>${cellValue || '-'}</span>`;
                }
            },
        },
        {
            width: "170",
            prop: "payTime",
            label: "交易完成时间",
        },
        {
            width: "170",
            prop: "withdrawApplyTime",
            label: "申请提现时间",
        },
        {
            width: "170",
            prop: "withdrawFinishTime",
            label: "完成提现时间",
        },
    ];
    private tableHeader1 = [
        {
            width: "170",
            prop: "applyTime",
            label: "申请提现时间",
        },
        {
            width: "150",
            align: "right",
            prop: "withdrawAmount",
            label: "提现金额",
        },
        {
            width: "100",
            prop: "statusName",
            label: "状态",
            formatter: (row: any, column: any, cellValue: any, index: any) => {
                // 待处理
                if (row.status === 'wait_process') {
                    return `<span style="color: #409EFF;">${cellValue}</span>`;
                // 已拒绝
                } else if (row.status === 'rejected') {
                    return `<span style="color: rgba(255, 0, 0, 0.647058823529412);">${cellValue}</span>`;
                // 已提现
                } else if (row.status === 'withdrawal') {
                    return `<span style="color: #36AB60;">${cellValue}</span>`;
                } else {
                    return `<span>${cellValue || '-'}</span>`;
                }
            },
        },
        {
            width: "350",
            prop: "depositName",
            label: "银行名称",
        },
        {
            width: "350",
            prop: "cardNo",
            label: "银行卡号",
        },
        {
            width: "350",
            prop: "processTime",
            label: "完成提现时间",
        },
    ];
    // 申请提现
    private applyCashFlag: boolean = false;
    // 明细
    private cashDetailFlag: boolean = false;
    private cashInfo: any = {};
    // 分润信息
    private profitTab: any = {};
    // 订单详情
    private orderDetailFlag: boolean = false;
    private orderInfo: any = {};

    // 去认证
    private openCert() {
        this.$router
        .push(`/company/Certification`)
        .catch((err) => err);
    }
    private created() {
        this.certStatus = this.$store.state.userInfo.certStatus;
        this.getProfitOverview();
        this.getDataList();
        this.getCurrentRequest();
    }

    private getCurrentRequest() {
        const paramData: any = {};
        paramData.preventRepeat = 1;
        paramData.notLoading = true;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/users/current').then((res: any) => {
            if (res.partnerStatus !== 3 && res.partnerStatus !== 4) {
                // ...
            } else {
                this.$router.push('/company/403').catch((err) => err);
            }
        });
    }

    private getProfitOverview() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/profit/overview')
        .then((res: any) => {
            this.profitTab = res;
        });
    }

    private tabChange() {
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private handleSizeChange(val: any) {// 切换页数
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: any) {// 切换页码
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDataList() {
        const searchParam: any = Object.assign({}, this.searchParam);
        if (this.activePage === '1') {
            this.$httpService.getData(searchParam, `/apiProxy/api/frontend/profit/page`)
            .then((res: any) => {
                if (res && res.total > 0) {
                    this.tableData = res.list;
                    this.total = res.total;
                } else {
                    this.tableData = [];
                    this.total = 0;
                }
            });
        } else if (this.activePage === '2') {
            this.$httpService.getData(searchParam, `/apiProxy/api/frontend/profit/withdraw/page`)
            .then((res: any) => {
                if (res && res.total > 0) {
                    this.tableData = res.list;
                    this.total = res.total;
                } else {
                    this.tableData = [];
                    this.total = 0;
                }
            });
        }
    }
    private handleCash() {
        this.applyCashFlag = true;
    }
    private closeHandleApplyCash(num: number) {
        this.applyCashFlag = false;
        if (num === 1) {
            this.getProfitOverview();
            this.getDataList();
        }
    }
    private selectCashDetail(row: any) {
        this.cashInfo = row;
        this.cashDetailFlag = true;
    }
    private beforeCloseCashDetailDrawer() {
        this.cashDetailFlag = false;
    }
    private closeHandleCashDetailDrawer(tab: any) {
        if (tab.handleType === 1) {
            this.orderDetailFlag = true;
            this.orderInfo.id = tab.orderNo;
        } else {
            this.cashDetailFlag = false;
        }
    }
    private detailClick(row: any) {
        this.orderDetailFlag = true;
        this.orderInfo.id = row.orderNo;
    }
    private closeHandleOrderDetail(num: number) {
        this.orderDetailFlag = false;
    }
}
