
import { Component, Vue, Prop, InjectReactive, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import WangEditor from "@/components/WangEditor.vue";

@Component({
    name: 'DriveDetail',
    components: {
        ContentTitle,
        WangEditor,
    },
})
export default class DriveDetail extends Vue {
    @InjectReactive('authCodeId') private authCodeId!: number;
    @InjectReactive('authType') private authType!: number;
    private contentTitle: string = '驱动详情';
    private status: any = null;
    private tableData: object[] = [];
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private driveDetailForm: any = {
        authCode: '',
        devs: [],
        driveName: '',
        version: '',
        fileUrl: '',
        fileName: '',
        price: '',
        coverUrl: '',
        wordUrl: '',
        wordName: '',
        description: '',
        reason: '',
        id: '',
        type: null,
        remark: '',
    };
    get devList() {
        if (this.authType === 1) {
            return this.driveDetailForm.devs;
        }
        return this.driveDetailForm.devs.filter((item: any) => item.name !== '-');
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        return num;
    }
     // 给父组件传值
    @Emit('editHandle')
    private editTodo() {
        // ...
    }
    get statusName() {
        let statusName = '';
        if (this.status === 1) {
            statusName = "审核中";
        } else if (this.status === 3) {
            statusName = "已拒绝";
        } else if (this.status === 4) {
            statusName = "已下架";
        }
        return statusName;
    }
    get showPriceName() {
        let statusName = '';
        if (this.driveDetailForm.price === 0) {
            statusName = "免费";
        } else if (this.driveDetailForm.price) {
            statusName = `<span>￥${this.driveDetailForm.price}&ensp;&ensp;元</span>`;
        } else {
            statusName = '未设置';
        }
        return statusName;
    }
    private created() {
        this.getDriveDetailInfo();
    }
    private getDriveDetailInfo() {
        this.$httpService.postData({}, `/apiProxy/api/frontend/drive/info/${this.authCodeId}`)
        .then((rep: any) => {
            if (rep) {
                Object.keys(this.driveDetailForm).forEach((key: string) => {
                    if (rep.hasOwnProperty(key)) {
                        this.driveDetailForm[key] = rep[key];
                    }
                });
                if (Array.isArray(this.driveDetailForm.devs) && this.driveDetailForm.devs.length) {
                    const dev: any = this.driveDetailForm.devs[0];
                    if (dev.hasOwnProperty('coverUrl')) {
                        this.driveDetailForm.coverUrl = dev.coverUrl;
                    }
                }
                this.status = rep.status;
            }
        });
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
    private underHandle() {
        this.$confirm(`是否确认下架`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.postData({}, `/apiProxy/api/frontend/drive/code-offline/${this.authCodeId}`)
            .then((rep: any) => {
                if (rep) {
                    this.$message.success('下架成功');
                    this.closeTodo(2);
                }
            });
        });
    }
    private revokeAuditHandle() {
        this.$confirm(`是否确认撤销审核`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.postData({}, `/apiProxy/api/frontend/drive/cancel-online/${this.authCodeId}`)
            .then((rep: any) => {
                if (rep) {
                this.$message.success('撤销审核成功');
                this.closeTodo(2);
                }
            });
        });
    }
    private editHandle() {
        this.editTodo();
    }
    //  下载附件
    private async downloadFile(fileUrl: any, fileName: any) {
        this.$message.success('文件下载中，请稍等...');
        const response = await fetch(this.baseUrl  + fileUrl);
        const blob = await response.blob();
        this.triggerDownload(URL.createObjectURL(blob), fileName);
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    private defaultTypeFormatter(row: any, column: any, cellValue: any, index: any) {
        if (!cellValue || cellValue === '') {
            return '-';
        } else {
            return cellValue;
        }
    }
}
