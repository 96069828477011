
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ApplyJoin from "./component/ApplyJoinDialog.vue";

@Component({
    name: "ecoPartner",
    components: {
        ContentTitle,
        ApplyJoin,
    },
})
export default class EcoPartner extends Vue {
    private loading: boolean = false;
    private contentTitle: string = "生态合作";
    private certStatus: number = 0;
    private applyJoinType: number = 0; // 1，加入流程；2，续约流程；2，变更流程
    private agreementData: any = [];
    private applyData: any = [];
    private applyJoinFlag: boolean = false;
    private ecologyInfo: any = {};
    private joinProcessStatus: any = 1;
    private agreementParam: any = {
        pageSize: 5,
        pageNo: 1,
    };
    private agreementTotal: number = 0;
    private applyParam: any = {
        pageSize: 5,
        pageNo: 1,
    };
    private applyTotal: number = 0;

    private created() {
        this.loading = true;
        this.certStatus = this.$store.state.userInfo.certStatus;
        this.getEcologyInfo();
        if (this.certStatus === 2) {
            setTimeout(() => {
                this.getUserCurrent();
            }, 1500);
        }
    }

    private getEcologyInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/ecology/partner')
        .then((res: any) => {
            this.ecologyInfo = res;
            this.joinProcessStatus = res.joinProcessStatus;
            if (this.joinProcessStatus !== 1 || this.ecologyInfo.applyType === 2 || this.ecologyInfo.applyType === 3) {
                this.getAgreementList();
                this.getApplyList();
            }
        }).finally(() => {
            this.loading = false;
        });
    }
    // 获取当前用户信息
    private getUserCurrent() {
        const paramData: any = {};
        paramData.notLoading = true;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/users/current').then((res: any) => {
            this.$store.commit('saveUserInfo', res);
        });
    }
    // 获取企业申请记录分页
    private getApplyList() {
        const paramData: any = Object.assign({}, this.applyParam);
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/ecology/partner/apply/record/page')
        .then((res: any) => {
            if (res && res.total > 0) {
                this.applyData = res.list;
                this.applyTotal = res.total;
            } else {
                this.applyData = [];
                this.applyTotal = 0;
            }
        });
    }
    // 获取企业协议记录分页
    private getAgreementList() {
        let paramData: any = {};
        paramData = Object.assign({}, this.agreementParam);
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/ecology/partner/contract/file/page')
        .then((res: any) => {
            if (res && res.total > 0) {
                this.agreementData = res.list;
                this.agreementTotal = res.total;
            } else {
                this.agreementData = [];
                this.agreementTotal = 0;
            }
        });
    }

    private handleAgreementCurrentChange(val: number) {
        this.agreementParam.pageNo = val;
        this.getAgreementList();
    }
    private handleApplyCurrentChange(val: number) {
        this.applyParam.pageNo = val;
        this.getApplyList();
    }

    private applyJoinClick(applyJoinType: number) {
        this.applyJoinType = applyJoinType;
        this.applyJoinFlag = true;
    }
    private applyJoinHandleClose(num: any) {
        this.applyJoinFlag = false;
        if (num === 1) {
           this.getEcologyInfo();
        }
    }
    // 下载文件 active：0 默认截取扩展名之前的名字
    private async downloadClick(fileUrl: any, fileName: any, active = 0) {
        this.$message.success('文件下载中，请稍等');
        if (!fileName) {
            fileName = new Date().getTime();
        }
        // if (active === 0) {
        //     const fileFullName = fileName;
        //     if (fileFullName && fileFullName !== '') {
        //         fileName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
        //     }
        // }
        const baseUrlIframe = process.env.VUE_APP_URL;
        const response = await fetch(baseUrlIframe + fileUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), fileName);
    }
    private triggerADownload(blob: any, fileName: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    private gotoCustomer() {
        this.$router
        .push(`/company/reportCustomer?type=ecology`)
        .catch((err) => err);
    }
    // 去认证
    private openCert() {
        this.$router
        .push(`/company/Certification`)
        .catch((err) => err);
    }
    private fileExist(val: any) {
        if (val && val !== '') {
            return true;
        }
        return false;
    }
}
