
import { ElForm } from 'element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { verifyPhone } from '@/utils/utils';

@Component({
    name: 'ApplyJoin',
})
export default class ApplyJoin extends Vue {
    private showDialog: boolean = false; // 弹窗显示与关闭
    private title: string = '申请加入';
    private selectList: any = [];
    private formData: any = {
        // contact: '',
        // telephone: '',
        intendedPartnerType: [],
    };
    private formRules: any = {
        contact: [
            { required: true, message: '请输入联系人', trigger: 'change' },
        ],
        telephone: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        intendedPartnerType: [
            { required: true, message: '请选择意向伙伴', trigger: 'change' },
        ],
    };
    @Prop({
        type: Number,
        default: 1,
    }) private applyJoinType!: number; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private detailInfo!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private existFlag!: boolean; // 接收父组件传过来的值
    @Watch('existFlag') // 监听父组件传过来的值
    private existFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.selectList = [];
            if (this.applyJoinType === 1) {
                this.title = '申请加入';
            } else if (this.applyJoinType === 2) {
                this.title = '申请续约';
            } else if (this.applyJoinType === 3) {
                this.title = '申请变更伙伴类型';
            }
        }
    }
    private changeMobileNum() {
        this.formData.telephone = this.formData.telephone.replace(/[^\d]/g, '');
    }
    private validateMobile(rule: any, value: any, callback: any) {
        verifyPhone(value) ? callback() : callback(new Error('请输入正确的手机号'));
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.handleCloseTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('handleClose')
    private handleCloseTodo(num: number): number {
        setTimeout(() => {
            this.formData = {
                contact: '',
                telephone: '',
                intendedPartnerType: [],
            };
            this.resetForm('refForm');
        }, 200);
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private submit() {
        if (this.selectList.length <= 0) {
            this.$message.warning('选择意向伙伴类型');
            return;
        }
        this.submitRequest();
    }
    private submitRequest() {
        if (this.applyJoinType === 1) {
            this.joinRequest();
        } else if (this.applyJoinType === 2) {
            this.renewContractRequest();
        } else if (this.applyJoinType === 3) {
            this.changeRequest();
        }
    }
    // 加入
    private joinRequest() {
        const paramData: any = {};
        paramData.intendedPartnerType = this.selectList.sort();
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/ecology/partner/apply-settled')
        .then((res: any) => {
            this.$message.success('申请成功');
            this.handleCloseTodo(1);
        });
    }
    // 变更
    private changeRequest() {
        const paramData: any = {};
        paramData.intendedPartnerType = this.selectList.sort();
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/ecology/partner/change-partner-type')
        .then((res: any) => {
            this.$message.success('申请成功');
            this.handleCloseTodo(1);
        });
    }
    // 续约
    private renewContractRequest() {
        const paramData: any = {};
        paramData.intendedPartnerType = this.selectList.sort();
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/ecology/partner/apply-renew')
        .then((res: any) => {
            this.$message.success('申请成功');
            this.handleCloseTodo(1);
        });
    }
    private selectItem(val: any) {
        if (this.contains(val)) {
            this.arrRemove(val);
        } else {
            this.selectList.push(val);
        }
    }
    private arrRemove(val: any) {
        const index = this.selectList.indexOf(val);
        if (index > -1) {
            this.selectList.splice(index, 1);
        }
    }
    private contains(val: any) {
        return this.selectList.some((item: any) => item === val);
    }
}
