
import { Component, Vue, Prop, InjectReactive, Emit } from 'vue-property-decorator';
import WangEditor from "@/components/WangEditor.vue";
import { ElForm } from 'element-ui/types/form';
import { priceParse } from "@/utils/utils";
import ApplyAuthorize from "./ApplyAuthorizeDialog.vue";

@Component({
    name: 'CommonDriveUp',
    components: {
        WangEditor,
        ApplyAuthorize,
    },
})
export default class CommonDriveUp extends Vue {
    @InjectReactive('authCodeId') private authCodeId!: number;
    private status: number = 0;
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private authorizeDialog?: boolean = false;
    private commonDriveForm: any = {
        authCode: '',
        devs: [],
        version: '',
        fileUrl: '',
        fileName: '',
        price: '',
        wordUrl: '',
        wordName: '',
        driveName: '',
        coverUrl: '',
        description: '',
        id: '',
        remark: '',
    };
    private commonDriveRules: any = {
        driveName: [
            { required: true, message: '请输入驱动名称', trigger: 'change' },
        ],
        version: [
            { required: true, message: '请输入驱动版本', trigger: 'change' },
            { validator: this.validatorVersion, trigger: 'blur' },
        ],
        fileUrl: [
            { required: true, message: '请上传驱动压缩包', trigger: 'change' },
        ],
        price: [
            { required: true, message: '请输入驱动商品价格', trigger: 'change' },
            // { validator: this.validatorPrice, trigger: "blur" },
        ],
        coverUrl: [
            { required: true, message: '请上传封面图片', trigger: 'change' },
        ],
        wordUrl: [
            { required: true, message: '请上传使用说明文档', trigger: 'change' },
        ],
        remark: [
            { required: true, message: '请输入备注', trigger: 'change' },
        ],
        description: [
            { required: true, message: '请输入描述内容', trigger: 'change' },
        ],
    };
    get devList() {
        console.log(this.commonDriveForm.devs);
        return this.commonDriveForm.devs.filter((item: any) => item.name !== '-');
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        return num;
    }
    private created() {
        this.getCommonDriveInfo();
    }
    private getCommonDriveInfo() {
        this.$httpService.postData({}, `/apiProxy/api/frontend/drive/info/${this.authCodeId}`)
        .then((rep: any) => {
            if (rep) {
                Object.keys(this.commonDriveForm).forEach((key: string) => {
                    if (rep.hasOwnProperty(key)) {
                        this.commonDriveForm[key] = rep[key];
                    }
                });
                if (Array.isArray(this.commonDriveForm.devs) && this.commonDriveForm.devs.length) {
                    const dev: any = this.commonDriveForm.devs[0];
                    if (dev.hasOwnProperty('coverUrl')) {
                        this.commonDriveForm.coverUrl = dev.coverUrl;
                    }
                }
                this.status = rep.status;
            }
        });
    }
    private validatorPrice(rule: any, value: any, callback: any) {
        if (!Number(value)) {
            return callback(new Error('请输入数字值'));
        }
        const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})$/g; // 小数限制0-2位
        // const reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
        if (!reg.test(value)) {
            return callback(new Error('请输入正确价格'));
        }
        callback();
    }
    private validatorVersion(rule: any, value: any, callback: any) {
        const reg = /^(V|v)([1-9]\d?)(\.([1-9]?\d)){2}$/;
        if (!reg.test(value)) {
            return callback(new Error('驱动版本格式不正确'));
        }
        callback();
    }
    private priceParse() {
        const value = priceParse(this.commonDriveForm.price);
        this.$set(this.commonDriveForm, 'price', value);
    }
    private cancelEdit() {
        this.$confirm(`是否确认取消编辑，取消后所编辑的内容将不被保存`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.closeTodo(1);
        });
    }
    private saveHandle(formRefName: any, editorFormRefName: any, type: number) {
        const formRef = this.$refs[formRefName] as ElForm;
        const editorFormRef = this.$refs[editorFormRefName] as ElForm;
        let fromValidate = true;
        formRef.validate((isValid: boolean) => {
            if (!isValid) {
                fromValidate = false;
            }
        });
        editorFormRef.validate((isValid: boolean) => {
            if (!isValid) {
                fromValidate = false;
            }
        });
        if (fromValidate) {
            const paramData = Object.assign({}, this.commonDriveForm);
            if (paramData.devs.length) {
                paramData.devs[0].goodsName = paramData.driveName;
                paramData.devs[0].coverUrl = paramData.coverUrl;
            }
            paramData.remark = paramData.remark.trim();
            if (type === 1) {
                this.$httpService.postData(paramData, `/apiProxy/api/frontend/drive/edit/${this.authCodeId}`)
                .then((rep: any) => {
                    this.$message.success('保存成功');
                    this.closeTodo(2);
                });
            } else if (type === 2) {
                this.$httpService.postData(paramData, `/apiProxy/api/frontend/drive/apply-online/${this.authCodeId}`)
                .then((rep: any) => {
                    this.$message.success('保存并提交审核成功');
                    this.closeTodo(2);
                });
            }
        }
    }
    private validateDesc() {
        const formRef = this.$refs.commonDriveEditorForm as ElForm;
        formRef.validateField('description');
    }
    private propWangEditor(html: any) {
        console.log('html', html);
        this.commonDriveForm.content = html;
    }
    // *******************文件上传******************* //
    private beforeAvatarUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isJPG = file.type;
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (isJPG !== "image/jpeg" && isJPG !== "image/jpg"  && isJPG !== "image/png") {
                this.$message.warning('支持.jpg、.jpeg、.png格式图片上传，建议使用png图片格式');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            if (!isLt2M) {
                this.$message.warning('上传图片大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    // 添加封面图片
    private httpRequestAvatarBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.commonDriveForm.coverUrl = res.path;
            const formRef = this.$refs.commonDriveForm as ElForm;
            formRef.validateField('coverUrl');
        });
    }
    private beforeCompressFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const fileType = file.name.replace(/.+\./, "");
            if (['zip', 'rar'].indexOf(fileType.toLowerCase()) === -1) {
                this.$message.warning('支持.zip、.rar格式文件上传');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            const isLt2M = file.size / 1024 / 1024 < 30;
            if (!isLt2M) {
                this.$message.warning('压缩文件大小不能超过 30MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    // 添加压缩文件
    private httpRequestCompressBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            // ...
            this.commonDriveForm.fileUrl = res.path;
            this.commonDriveForm.fileName = data.file.name;
            const formRef = this.$refs.commonDriveForm as ElForm;
            formRef.validateField('fileUrl');
        });
    }
    private beforeWordFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const fileType = file.name.replace(/.+\./, "");
            if (['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(fileType.toLowerCase()) === -1) {
                this.$message.warning('支持.pdf、.doc、.docx、.txt、.xls、.xlsx、.ppt、.pptx格式文件上传');
                return reject(false); // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            const isLt2M = file.size / 1024 / 1024 < 30;
            if (!isLt2M) {
                this.$message.warning('说明文档大小不能超过 30MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    // 添加文档说明
    private httpRequestWordBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            // ...
            this.commonDriveForm.wordUrl = res.path;
            this.commonDriveForm.wordName = data.file.name;
            const formRef = this.$refs.commonDriveForm as ElForm;
            formRef.validateField('wordUrl');
        });
    }
    private editDrive() {
        this.authorizeDialog = true;
    }
    private authorizeClose(num: number) {
        this.authorizeDialog = false;
        if (num === 2) {
            console.log(2);
            this.getCommonDriveInfo();
        }
    }
    private authorizeSaveHandle(devs: any) {
        this.authorizeDialog = false;
        this.commonDriveForm.devs = devs;
    }
    private defaultTypeFormatter(row: any, column: any, cellValue: any, index: any) {
        if (!cellValue || cellValue === '') {
            return '-';
        } else {
            return cellValue;
        }
    }
}
