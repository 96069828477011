
import { ElForm } from 'element-ui/types/form';
import { Component, Vue, Prop, InjectReactive, Emit } from 'vue-property-decorator';
import ApplyAuthorizeDriveItem from './ApplyAuthorizeDrive.vue';

@Component({
    name: 'ApplyAuthorize',
    components: {
        ApplyAuthorizeDriveItem,
    },
})
export default class ApplyAuthorize extends Vue {
    @InjectReactive('authType') private authType!: number;
    @Prop({
        type: Boolean,
        default: false,
    }) private editFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private isEdit?: boolean;
    @Prop({
        type: Array,
        default: (() => []),
    }) private devs?: any;
    @Prop(Array) private result?: object;
    private authCodeDataList: object[] = [];
    get showDialog() {
        return this.editFlag;
    }
    set showDialog(value) {
        // ...
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        // const obj = this.createDefaultAuthCodeData();
        // this.authCodeDataList = [obj];
        return num;
    }
     // 给父组件传值
    @Emit('saveHandle')
    private saveHandleTodo(devList: any): any {
        this.showDialog = false;
        // const obj = this.createDefaultAuthCodeData();
        // this.authCodeDataList = [obj];
        return devList;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(1); // 给父组件传值
    }
    private created() {
        if (!this.isEdit) {
            const obj = this.createDefaultAuthCodeData();
            this.authCodeDataList = [obj];
        } else {
            this.devs.forEach((item: any) => {
                const obj: any = this.createDefaultAuthCodeData();
                Object.keys(obj).forEach((key: any) => {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'industry') {
                            obj[key] = item[key].split(',').map(Number);
                        } else {
                            obj[key] = item[key];
                        }
                    }
                });
                this.authCodeDataList.push(obj);
            });
            if (this.authCodeDataList.length === 0) {
                const obj = this.createDefaultAuthCodeData();
                this.authCodeDataList = [obj];
            }
        }
    }
    private submit(elForm: string) {
        let fromValidate = true;
        this.authCodeDataList.forEach((item, index) => {
            const elFormRef = this.$refs[`${elForm}${index}`] as any;
            const subElFormRef = elFormRef[0].$refs.authorizeForm as ElForm;
            subElFormRef.validate((valid: any) => {
                if (!valid) {
                    fromValidate = false;
                }
            });
        });
        if (fromValidate) {
            const devList = this.authCodeDataList.map((item: any) => {
                item.industry = item.industry.join(',');
                if (Object.is(item.industry, 'NaN')) {
                    item.industry = '-';
                }
                if (!this.isEdit) {
                    delete item.industryName;
                }
                return item;
            });
            if (!this.isEdit) {
                this.authCodeApplyRequest(devList);
            } else {
                this.saveHandleTodo(devList);
            }
        }
    }
    // 专用驱动授权码申请
    private authCodeApplyRequest(devList: any) {
        const paramData: any = {};
        paramData.devs = devList;
        paramData.type = this.authType;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/drive/apply-code')
        .then((res: any) => {
            this.$message.success('保存成功');
            this.closeHandleTodo(2); // 给父组件传值
        });
    }
    private addDrive() {
        const obj = this.createDefaultAuthCodeData();
        this.authCodeDataList.push(obj);
        this.$nextTick(() => {
            const driveListNode: any = this.$refs['apply-drive'];
            driveListNode.scrollTo({top: driveListNode.scrollHeight, behavior: 'smooth'});
        });
    }
    private createDefaultAuthCodeData() {
        return {
            id: this.isEdit ? 0 : '',
            coverUrl: '',
            name: '',
            industry: '',
            industryName: '',
            vendor: '',
            model: '',
        };
    }
    private deleteCurrentDrive(index: number) {
        if (this.authCodeDataList.length > index) {
            this.authCodeDataList.splice(index, 1);
        }
    }
}
