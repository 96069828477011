
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, ProvideReactive } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ApplyAuthorize from "./component/ApplyAuthorizeDialog.vue";
import DriveUpEdit from "./component/DriveUpEdit.vue";
import DevAuthorizeCodeList from "./component/DevAuthorizeCodeList.vue";
import DriveUpList from "./component/DriveUpList.vue";
import DriveDetail from "./component/DriveDetail.vue";

enum IndexType {
    ActiveIndexTypeDev = 1,
    ActiveIndexTypeUp = 2,
}
enum PageType {
    ActivePageTypeMain = 1,
    ActivePageTypeEdit = 2,
    ActivePageTypeDetail = 3,
}

@Component({
    name: "DriveDevelopment",
    components: {
        ContentTitle,
        ApplyAuthorize,
        DriveUpEdit,
        DevAuthorizeCodeList,
        DriveUpList,
        DriveDetail,
    },
})
export default class DriveDevelopment extends Vue {
    @ProvideReactive('authCodeId') private authCodeId?: number;
    @ProvideReactive('authType') private authType?: number;
    private contentTitle: string = '设备驱动程序开发';
    private indexType: any = IndexType;
    private pageType: any = PageType;
    private activeIndex: any = IndexType.ActiveIndexTypeDev; // 1，开发授权码；2，驱动上架
    private activePage: any = PageType.ActivePageTypeMain; // 1，主页面；2，驱动详情
    // ApplyAuthorize start
    private authorizeDialog: boolean = false;
    // end
    private codeNum: number = 0;
    private onlineNum: number = 0;
    private downloadDomain = 'https://wis3d-pro.morewiscloud.com';
    private created() {
        // ...
    }
    private getStatisticData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/drive/total')
        .then((rep: any) => {
            if (rep) {
                this.codeNum = rep.codeNum;
                this.onlineNum = rep.onlineNum;
            }
        });
    }
    private checkActive(active: any) {
        if (active !== this.activeIndex) {
            this.activeIndex = active;
        }
    }
    // 添加内容点击事件
    private handleCommand(command: any) {
        if (this.activeIndex === IndexType.ActiveIndexTypeDev) {
            this.authType = Number(command);
            if (this.authType === 1) {
                this.authorizeDialog = true;
            } else {
                this.commentAuthCodeApplyRequest();
            }
        }
    }
    // 通用授权码申请
    private commentAuthCodeApplyRequest() {
        const paramData: any = {};
        const comDevList = [{
                name: '-',
                industry: '-',
                vendor: '-',
                model: '-',
        }];
        paramData.devs = comDevList;
        paramData.type = this.authType;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/drive/apply-code')
        .then((res: any) => {
            this.$message.success('申请成功');
            this.authorizeClose(2);
        });
    }
    private driveEdit(obj: any) {
        this.authType = obj.type;
        this.authCodeId = obj.authCodeId;
        this.activePage = PageType.ActivePageTypeEdit;
    }
    private driveDetail(obj: any) {
        this.authType = obj.type;
        this.authCodeId = obj.authCodeId;
        this.activePage = PageType.ActivePageTypeDetail;
    }
    // 申请授权关闭
    private authorizeClose(num: number) {
        this.authorizeDialog = false;
        if (num === 2) {
            (this.$refs.devAuthorizeCodeList as DevAuthorizeCodeList).getDataList();
        }
    }
    // 驱动编辑关闭
    private driveEditClose(num: number) {
        this.activePage = this.pageType.ActivePageTypeMain;
        if (num === 2) {
            // this.$nextTick(() => {
            //     if (this.activeIndex === IndexType.ActiveIndexTypeDev) {
            //         (this.$refs.devAuthorizeCodeList as DevAuthorizeCodeList).getDataList();
            //     } else if (this.activeIndex === IndexType.ActiveIndexTypeUp) {
            //         (this.$refs.driveUpList as DriveUpList).getDataList();
            //     }
            // });
        }
    }
    // 驱动详情关闭
    private driveDetailClose(num: number) {
        this.activePage = this.pageType.ActivePageTypeMain;
    }
    private driveDetailEdit() {
        this.activePage = this.pageType.ActivePageTypeEdit;
    }
}
