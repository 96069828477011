
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { moneyFormat } from '@/utils/utils';
import { assembleOrderItemBuyout, isBuyCompletely } from '@/utils/assemble';

@Component({
    name: "OrderDetailDialog",
    components: {
        ContentTitle,
    },
})
export default class OrderDetailDialog extends Vue {
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private contentTitle: string = "商品订单详情";
    private orderInfo: any = {};
    @Prop({
        type: Boolean,
        default: false,
    }) private showFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private content!: any; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 0,
    }) private buyRole !: number;
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('showFlag') // 监听父组件传过来的值
    private showFlagFun(value: boolean) {
        console.log('showFlagFun', value);
        this.showDialog = value;
        if (value) {
            this.getOrderDetail();
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    private created() {
        console.log('created');
    }
    private getOrderDetail() {
        const formData: any = {
            pageNo: 1,
            pageSize: 1,
            buyRole: 0,
            orderNo: this.content.id,
        };
        this.$httpService.getData(formData, '/apiProxy/api/frontend/orders')
            .then((res: any) => {
                this.orderInfo = res.list[0];
                if (isBuyCompletely(this.orderInfo.orderGoodsDetailList)) {
                    // 买断产品和授权用户数 上下显示
                    const detailListCopy: any = assembleOrderItemBuyout(this.orderInfo.orderGoodsDetailList);
                    this.orderInfo.orderGoodsDetailList = [].concat(detailListCopy);
                }
            });
    }
    private previewList(imageList: any) {
        return imageList.map((item: any) => `${this.baseUrl}${item.image}`);
    }
    // 金额格式化
    private moneyFormat(value: number) {
        return moneyFormat(value, 2);
    }
}
