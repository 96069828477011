
import { Component, Vue, Emit, InjectReactive } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import SpecialDriveUp from "./SpecialDriveUp.vue";
import CommonDriveUp from "./CommonDriveUp.vue";

@Component({
    name: 'EditDrive',
    components: {
        ContentTitle,
        SpecialDriveUp,
        CommonDriveUp,
    },
})
export default class EditDrive extends Vue {
    @InjectReactive('authType') private authType!: number;
    private contentTitle: string = '驱动上架';
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        return num;
    }
    private created() {
        // ...
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
}
