
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import BaseTable from "@/components/BaseTable/index.vue";

@Component({
    name: 'DetailDrawer',
    components: {
        BaseTable,
    },
})
export default class DetailDrawer extends Vue {
    @Prop({
        type: Object,
        default: () => {
            return {};
        },
    }) private detailInfo!: any;
    private detailTab: any = {};
    private loading = true;
    private total = 0;
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
    };
    private tableData = [];
    private tableHeader = [
        {
            width: "150",
            prop: "orderNo",
            label: "订单编号",
            render: (h: any, scope: any) => {
                return h('span', {
                    class: ['text-blue'],
                    on: {
                        click: () => this.detailClick(scope.row),
                    },
                }, `${scope.row.orderNo}`);
            },
        },
        {
            width: "200",
            prop: "goodsTitle",
            label: "商品名称",
        },
        {
            width: "260",
            prop: "belongCompanyName",
            label: "归属客户",
        },
        {
            width: "150",
            align: "right",
            prop: "payAmount",
            label: "实付金额",
        },
        {
            width: "150",
            align: "right",
            prop: "profitAmount",
            label: "分润金额",
        },
        {
            width: "170",
            prop: "payTime",
            label: "交易完成时间",
        },
    ];
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(tab: any): any {
        return tab;
    }
    private created() {
        this.getDetail();
        this.getDataList();
    }
    private handleSizeChange(val: any) {// 切换页数
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: any) {// 切换页码
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDetail() {
        const paramData: any = {};
        paramData.withdrawId = this.detailInfo.id;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/profit/withdraw/detail')
        .then((res: any) => {
            this.detailTab = res;
        });
    }
    private getDataList() {
        const searchParam: any = Object.assign({}, this.searchParam);
        searchParam.withdrawId = this.detailInfo.id;
        this.$httpService.getData(searchParam, '/apiProxy/api/frontend/profit/page')
        .then((res: any) => {
            if (res && res.total > 0) {
                this.tableData = res.list;
                this.total = res.total;
            } else {
                this.tableData = [];
                this.total = 0;
            }
        });
    }
    private detailClick(row: any) {
        this.closeHandleTodo({
            handleType: 1,
            orderNo: row.orderNo,
        });
    }
}
